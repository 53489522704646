export default ({ app }) ->
  # Listen for route changes to update the canonical link dynamically
  app.router.afterEach (to, from) ->
    if process.client
      # Try to find the canonical link element
      canonicalLink = document.querySelector("link[rel='canonical']")
      
      # If it exists, update its href attribute
      if canonicalLink
        # Construct the new URL using the current path and origin
        canonicalURL = window.location.origin + to.fullPath
        
        # Update the href attribute of the canonical tag
        canonicalLink.setAttribute 'href', canonicalURL

      # If canonical link does not exist, create one dynamically
      else
        canonicalURL = window.location.origin + to.fullPath
        newCanonicalLink = document.createElement('link')
        newCanonicalLink.setAttribute 'rel', 'canonical'
        newCanonicalLink.setAttribute 'href', canonicalURL
        document.head.appendChild(newCanonicalLink)
